<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput class="is-invalid" label="ລະຫັດລູກຄ້າ :" prop="cus_id" v-model="form.cus_id" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື່ :"
              prop="cus_full_name"
              v-model="form.cus_full_name"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="ທີ່ຢູ່ :" prop="cus_address" v-model="form.cus_address" />
        </CCol>
        <CCol sm="6">
          <CInput label="ເບີໂທຕິດຕໍ່ :" prop="cus_tel" v-model="form.cus_tel" />
        </CCol>
        <CCol sm="6">
          <CInput label="ເມວ :" prop="cus_email" v-model="form.cus_email" />
        </CCol>
        <CCol sm="6">
          <div class="form-group">
            <label>ວັນທີ່ເປັນສະມະຊີກ :</label>
            <date-picker :config="options" prop="cus_date" v-model="form.cus_date"></date-picker>
          </div>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ຈັດໃນກຸ່ມ :</label>
              <v-select
                class="is-invalid"
                prop="cus_group"
                v-model="form.cus_group"
                :options="groupList"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "CustomerFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        cus_id: "@3c-linklaos.com",
        cus_full_name: "",
        cus_address: "",
        cus_tel: "",
        cus_email: "",
        cus_group: "",
        cus_date: "",
      },
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      groupList: ["ລູກຄ້າທົ່ວໄປ", "ລູກຄ້າບໍລິສັດ", "ລູກຄ້າພິເສດ"],
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        cus_id: this.form.cus_id,
        cus_full_name: this.form.cus_full_name,
        cus_address: this.form.cus_address,
        cus_tel: this.form.cus_tel,
        cus_email: this.form.cus_email,
        cus_group: this.form.cus_group,
        cus_date: this.form.cus_date,
        cus_create_at: decrypt(this.$store.getters.getFullName),
      });
      this.form.cus_id = "";
      this.form.cus_full_name = "";
      this.form.cus_address = "";
      this.form.cus_tel = "";
      this.form.cus_email = "";
      this.form.cus_group = "";
      this.form.cus_date = "";
    },
  },
};
</script>