<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນລູກຄ້າ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເພີ້ມລູກຄ້າ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem v-on:click="onClickUpdate(row.item)">ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem :disabled="userGroup != 'administrator'" v-on:click="onClickDelete(row.item)">ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- Modal Create New Branch -->
    <CModal
      title="ຟອມເພີ້ມຂໍ້ມູນລູກຄ້າ"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <customerFormCreate @on_success="onSubmitCreate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <!-- Modal Update Branch -->
    <CModal
      title="ຟອມແກ້ໄຂຂໍ້ມູນລູກຄ້າ"
      size="lg"
      :show.sync="modalUpdate"
      :closeOnBackdrop="false"
    >
      <customerFormUpdate :customer="customerData" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import {
  getCustomerAPI,
  createCustomerAPI,
  updateCustomerAPI,
  deleteCustomerAPI,
} from "@/api/customer";
import customerFormCreate from "./components/CustomerFormCreate";
import customerFormUpdate from "./components/CustomerFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Customer",
  components: {
    customerFormCreate,
    customerFormUpdate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalUpdate: false,
      items: [],
      fields: [
        { key: "cus_id", label: "ລະຫັດລູກຄ້າ" },
        { key: "cus_full_name", label: "ຊື່" },
        { key: "cus_address", label: "ທີ່ຢູ່" },
        { key: "cus_tel", label: "ເບີໂທ" },
        { key: "cus_email", label: "ເມວ" },
        { key: "cus_group", label: "ຢູ່ໃນກຸ່ມ" },
        { key: "cus_date", label: "ວັນເປັນສະມະຊີກ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      customerData: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getCustomerAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },

    // Create New
    onSubmitCreate(credentials) {
      createCustomerAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },

    // Click Update button
    onClickUpdate(item) {
      this.customerData = item;
      this.modalUpdate = true;
    },
    // function update
    onSubmitUpdate(credentials) {
      updateCustomerAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },

    // Click delete button
    onClickDelete(item) {
      let onOk = () => {
        deleteCustomerAPI(item.cus_id)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>