var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('CForm',{ref:"form",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{staticClass:"is-invalid",attrs:{"label":"ລະຫັດລູກຄ້າ :","prop":"cus_id","disabled":""},model:{value:(_vm.form.cus_id),callback:function ($$v) {_vm.$set(_vm.form, "cus_id", $$v)},expression:"form.cus_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{staticClass:"is-invalid",attrs:{"label":"ຊື່ :","prop":"cus_full_name"},model:{value:(_vm.form.cus_full_name),callback:function ($$v) {_vm.$set(_vm.form, "cus_full_name", $$v)},expression:"form.cus_full_name"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"label":"ທີ່ຢູ່ :","prop":"cus_address"},model:{value:(_vm.form.cus_address),callback:function ($$v) {_vm.$set(_vm.form, "cus_address", $$v)},expression:"form.cus_address"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"label":"ເບີໂທຕິດຕໍ່ :","prop":"cus_tel"},model:{value:(_vm.form.cus_tel),callback:function ($$v) {_vm.$set(_vm.form, "cus_tel", $$v)},expression:"form.cus_tel"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"label":"ເມວ :","prop":"cus_email"},model:{value:(_vm.form.cus_email),callback:function ($$v) {_vm.$set(_vm.form, "cus_email", $$v)},expression:"form.cus_email"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ວັນທີ່ເປັນສະມະຊີກ :")]),_c('date-picker',{attrs:{"config":_vm.options,"prop":"cus_date"},model:{value:(_vm.form.cus_date),callback:function ($$v) {_vm.$set(_vm.form, "cus_date", $$v)},expression:"form.cus_date"}})],1)]),_c('CCol',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ຈັດໃນກຸ່ມ :")]),_c('v-select',{staticClass:"is-invalid",attrs:{"prop":"cus_group","options":_vm.groupList},model:{value:(_vm.form.cus_group),callback:function ($$v) {_vm.$set(_vm.form, "cus_group", $$v)},expression:"form.cus_group"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CButton',{staticClass:"float-right",attrs:{"disabled":invalid,"type":"submit","variant":"outline","color":"warning"}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v("ບັນທຶກ ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }